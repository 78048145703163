import { Grid, Spinner, Text, VStack } from '@chakra-ui/react'
import { Container } from '@components/Container'
import Pagination, { FIRST_PAGE } from '@components/Pagination'
import { ProductCard } from '@components/Product/ProductCard/ProductCard'
import { useProductCount, useProducts } from '@src/hooks/api'
import { cleanObject } from '@src/utils/clean-object'
import { getSearchParams } from '@src/utils/get-search-params'
import { useRouter } from 'next/router'
import { memo, useEffect, useState } from 'react'
import ProductsListEmpty from './ProductsListEmpty'
import ProductsListFilters, { ProductsListFiltersData } from './ProductsListFilter'

interface ProductsListProps {
  allowSearch: boolean
  itemsPerPage: number
}

const ProductsList = ({ allowSearch, itemsPerPage }: ProductsListProps) => {
  const router = useRouter()
  const query = getSearchParams()

  const [filters, setFilters] = useState<ProductsListFiltersData>({
    name: query.name || undefined,
    firmness: query.firmness ? +query.firmness : undefined,
    category: query.category ? +query.category : undefined,
  })
  const [currentPage, setCurrentPage] = useState<number>(query.page || FIRST_PAGE)

  const updateProducts = () => {
    router.push(
      {
        pathname: router.asPath.split('?')[0],
        query: cleanObject({ ...filters, page: currentPage }),
      },
      undefined,
      { shallow: true }
    )
  }

  useEffect(() => {
    setCurrentPage(FIRST_PAGE)
    updateProducts()
  }, [filters])

  useEffect(() => updateProducts(), [currentPage])

  const { data: pagesQuantity = 1 } = useProductCount(filters, itemsPerPage)
  const { data: products = [], isLoading } = useProducts(filters, currentPage, itemsPerPage)

  return (
    <Container>
      <VStack maxWidth="1366px" width="100%" margin="auto" alignItems="start" spacing={8} gridGap="3rem">
        {allowSearch && <ProductsListFilters filters={filters} setFilters={setFilters} />}
        {isLoading && (
          <VStack width="100%" height="100%" alignItems="center" justifyContent="center">
            <Spinner color="blue" size="xl" />
            <Text marginTop={'1rem !important'}>Carregando produtos...</Text>
          </VStack>
        )}
        {!isLoading && products.length && (
          <Grid
            width="100%"
            templateColumns={{
              base: 'repeat(1, 100%)',
              md: 'repeat(1, 24.875rem)',
              lg: 'repeat(2, 24.875rem)',
              xl: 'repeat(3, 24.875rem)',
            }}
            rowGap={{ base: '1.5rem', md: '4rem' }}
            justifyContent={{ base: 'space-around', lg: 'space-between' }}
            marginBottom="48px"
          >
            {products.map(product => (
              <ProductCard
                id={product.id?.toString()}
                key={product.id}
                name={product.title}
                description={product.shortDescription}
                image={product.highlightImage}
                advantages={product.advantages}
              />
            ))}
          </Grid>
        )}
        {!isLoading && !products.length && <ProductsListEmpty />}
        <Pagination currentPage={currentPage} pagesQuantity={pagesQuantity} setCurrentPage={setCurrentPage} />
      </VStack>
    </Container>
  )
}

export default memo(ProductsList)
